





























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

@Component({
    components: {
    },
    model: {
        prop: "model",
        event: "visibilityChanged"
    }
})
export default class Popup extends Vue {
    // Member
    public visibility = false;
        
    // Properties
    @Prop({ required: false })
    public model?: boolean;

    @Prop({ required: true })
    public title!: string;

    @Prop({ required: false })
    public icon?: string;

    @Prop({ required: false })
    public maxWidth?: string;

    // Getter & Setter
    public get visible(): boolean {
        return this.model ?? this.visibility;
    }
    public set visible(value: boolean) {
        this.visibility = value;
        this.$emit('visibilityChanged', value);
    }
    
    // Watcher:

    // Lifecycle Methods:

    // Methods:
    public close(): void {
        this.visible = false;
    }
}
