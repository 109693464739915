import { render, staticRenderFns } from "./ConflictsCounter.vue?vue&type=template&id=7f9c5a70&scoped=true&"
import script from "./ConflictsCounter.vue?vue&type=script&lang=ts&"
export * from "./ConflictsCounter.vue?vue&type=script&lang=ts&"
import style0 from "./ConflictsCounter.vue?vue&type=style&index=0&id=7f9c5a70&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f9c5a70",
  null
  
)

export default component.exports