




























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Label from "@/components/Label.vue";


@Component({
    components: {
        Label
    }
})
export default class ConflictsCounter extends Vue {
    // Member:

    // Properties:
    @Prop({required: true})
    public count!: number;

    @Prop({required: true})
    public titleSingular!: string;

    @Prop({required: true})
    public titlePlural!: string;

    // Getter:

    // Component Lifecycle Methods:

    // Methods:
}
