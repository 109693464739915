

























import { FeedbackRequest, FeedbacksService } from "@/api/braendz";
import { BusyObject } from "@/models/Busy";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class ThumbsButtons extends Vue {
    // Member:
    public feedbackRequest = new BusyObject<FeedbackRequest>();

    // Properties:
    @Prop({required: true})
    public context!: string;

    @Prop({required: false})
    public data?: any;

    @Prop({required: false})
    public disabled?: boolean;

    @Prop({required: false})
    public xSmall?: boolean;

    @Prop({required: false})
    public small?: boolean;

    @Prop({required: false})
    public medium?: boolean;

    @Prop({required: false})
    public large?: boolean;

    @Prop({required: false})
    public xLarge?: boolean;

    @Prop({required: false})
    public tooltip?: 'top' | 'bottom' | 'left' | 'right'


    // Watcher

    // Getter:

    // Component Lifecycle Methods:

    // Methods:
    public submit(positive: boolean): void {
        this.feedbackRequest.create(async () => {
            const request = { 
                context: this.context,
                positive: positive,
                data: this.data
            } as FeedbackRequest;
            await FeedbacksService.submitFeedback(request);
            return request;
        });
    }
}
