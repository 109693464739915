

































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VueSpeedometer from "vue-speedometer"


@Component({
    components: {
        VueSpeedometer 
    }
})
export default class GaugeChart extends Vue {
    // Member:

    // Properties:
    @Prop({required: true})
    public value!: number;

    @Prop({required: false, type: Number, default: 0})
    public minValue!: number;

    @Prop({required: false, type: Number, default: 100})
    public maxValue!: number;

    @Prop({required: false, default: ''})
    public title?: string;

    @Prop({required: false, default: false, type: Boolean})
    public autoWidth!: boolean;

    @Prop({required: false})
    public width?: number;

    @Prop({required: false})
    public height?: number;

    @Prop({required: false, default: false, type: Boolean})
    public debugging!: boolean;

    // Getter:

    // Component Lifecycle Methods:

    // Methods:
}
