import { Domain } from "@/api/braendz";

export interface DomainsSummary {
    availabilityRation?: number;
    total?: DomainAvailabilityCounters;
    countryDomains?: DomainAvailabilityCounters;
    commonDomains?: DomainAvailabilityCounters;
    otherDomains?: DomainAvailabilityCounters;
}

export interface DomainAvailabilityCounters {
    available: number;
    registered: number;
}

export function CreateSummary(domains: Domain[]): DomainsSummary {
    const result = {} as DomainsSummary;

    for(const domain of domains) {
        if(!result.total) {
            result.total = { available: 0, registered: 0 }
        }
        result.total.available += domain.available ? 1 : 0;
        result.total.registered += !domain.available ? 1 : 0;

        if(domain.tldTags?.find(d => d.toLowerCase() === "common")) {
            if(!result.commonDomains) {
                result.commonDomains = { available: 0, registered: 0 }
            }
            result.commonDomains.available += domain.available ? 1 : 0;
            result.commonDomains.registered += !domain.available ? 1 : 0;
        }
        else if(domain.tldTags?.find(d => ["de", "eu", "em", "at", "ch", "de", "es", "fr", "us"].includes(d.toLowerCase()))) {
            if(!result.countryDomains) {
                result.countryDomains = { available: 0, registered: 0 }
            }
            result.countryDomains.available += domain.available ? 1 : 0;
            result.countryDomains.registered += !domain.available ? 1 : 0;
        }
        else {
            if(!result.otherDomains) {
                result.otherDomains = { available: 0, registered: 0 }
            }
            result.otherDomains.available += domain.available ? 1 : 0;
            result.otherDomains.registered += !domain.available ? 1 : 0;
        }
    }

    if(result.total) {
        result.availabilityRation = result.total.available / (result.total.available + result.total.registered);
    }

    return result;
}