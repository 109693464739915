




























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class DashboardCard extends Vue {
    // Member
        
    // Properties
    @Prop({ required: true })
    public title!: string;

    @Prop({ required: false })
    public subtitle?: string;

    @Prop({ required: false })
    public icon?: string;

    @Prop({ required: false })
    public loading?: boolean;

    @Prop({ required: false, default: true })
    public loaded?: any;

    // Getter & Setter
    
    // Watcher:

    // Lifecycle Methods:

    // Methods:
}
